<template>
  <v-alert
    dense
    outlined
    type="info"
    color="blue"
    class="mb-0 py-1 px-1"
  >
    <div v-if="allowedFilesCount">
      Arquivos permitidos:
      <span>
        {{ allowedFilesTxt }}
      </span>
    </div>
    <div v-if="uploadMaxFilesize">
      Tamanho máximo dos arquivos:
      <span :title="title">
        {{ uploadMaxFilesize }}
      </span>
    </div>
    <div>
      <slot name="upload-alert-extra-information"></slot>
    </div>
  </v-alert>
</template>

<script>
import AuthService from '@/services/AuthService';
import ServerConfigService from '@/services/ServerConfigService';

export default {
  name: 'UploadAlert',
  props: {
    allowedFiles: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      uploadMaxFilesize: null,
      postMaxSize: null,
      maxExecutionTime: null,
      maxInputTime: null,
      userIsAdmin: false
    };
  },
  created() {
    this.getPhpConfig();
    this.getUserIsAdmin();
  },
  computed: {
    allowedFilesCount() {
      return this.allowedFiles.length;
    },
    allowedFilesTxt() {
      return this.allowedFiles.join(', ');
    },
    title() {
      if (!this.userIsAdmin) {
        return '';
      }

      let title = [];
      if (this.uploadMaxFilesize) {
        title.push(`upload_max_filesize: ${this.uploadMaxFilesize}`);
      }

      if (this.postMaxSize) {
        title.push(`post_max_size: ${this.postMaxSize}`);
      }

      if (this.maxExecutionTime) {
        title.push(`max_execution_time: ${this.maxExecutionTime}`);
      }

      if (this.maxInputTime) {
        title.push(`max_input_time: ${this.maxInputTime}`);
      }

      return title.join(' ');
    }
  },
  methods: {
    getPhpConfig() {
      ServerConfigService.getPhpConfig()
        .then((response) => {
          const {
            uploadMaxFilesize,
            postMaxSize,
            maxExecutionTime,
            maxInputTime
          } = response.data;
          this.uploadMaxFilesize = uploadMaxFilesize;
          this.postMaxSize = postMaxSize;
          this.maxExecutionTime = maxExecutionTime;
          this.maxInputTime = maxInputTime;
        })
        .catch((error) => console.log('Error: ' + error));
    },
    async getUserIsAdmin() {
      this.userIsAdmin = await AuthService.userIsAdmin();
    }
  }
};
</script>
